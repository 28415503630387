import { Button, Grid } from "@mui/material";
import CustomText from "components/CustomText";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { finishLoading, startLoading } from "redux/action";
import { OrderActions } from "redux/model";
import { MealActions } from "redux/model/meal";
import { RootState } from "redux/reducers";
import mealsServices from "services/meals.services";
import { APIResponseCode, IMealPrice } from "utility/types";

const MealCount = ({
  onSelectMeal = () => null,
  onNext = () => null,
}: {
  onSelectMeal: (count: number) => void;
  onNext: () => void;
}) => {
  const dispatch = useDispatch();
  const [count, setCount] = useState<number>(0);
  const [selBox, setSelBox] = useState<{
    id: number;
    boxCount: number;
    mealPrice: number;
    totalPrice: number;
  }>({
    id: 0,
    boxCount: 0,
    mealPrice: 0,
    totalPrice: 0,
  });
  const [mealPrices, setMealPrices] = useState<IMealPrice[]>([]);
  const order = useSelector((state: RootState) => state.order.order ?? {});

  const handleSelMeal = (item: IMealPrice) => {
    onSelectMeal(item?.boxCount);
    setSelBox(item);
    setCount(item.boxCount);
    dispatch({
      type: MealActions.UPDATE_MEAL,
      payload: { selMealPrice: item },
    });
  };

  const handleNext = () => {
    dispatch({
      type: OrderActions.UPDATE_ORDER,
      payload: { order: { ...order, count: count } },
    });
    onNext();
  };

  const loadMealPrices = async () => {
    startLoading();
    const res = await mealsServices.getMealPrices();
    finishLoading();
    if (res.code === APIResponseCode.SUCCESS) {
      setMealPrices(res.data);
      setMealPrices(res.data);
      dispatch({ type: MealActions.SET_MEAL, payload: { mealPrices: res } });
    } else toast.warning(res.message);
  };

  useEffect(() => {
    loadMealPrices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex flex-col justify-between">
      <div>
        <CustomText className="font-pSemiBold text-xl">
          Select Number of Meals
        </CustomText>
        <CustomText className="font-pSemiBold pb-3 text-grayBlack">
          How many meals would you like to order?
        </CustomText>
        <div className="flex items-center gap-2">
          {mealPrices.map((item: IMealPrice, index: number) => (
            <div
              key={index}
              className={`cursor-pointer rounded-md py-3 px-6 border ${
                item?.boxCount === selBox?.boxCount
                  ? "bg-primary text-white"
                  : "border-black"
              }`}
              onClick={() => handleSelMeal(item)}
            >
              {item.boxCount} Meals
            </div>
          ))}
        </div>
      </div>
      <div>
        {Boolean(selBox?.boxCount) && (
          <div className="bg-bgColor relative p-4 rounded-md my-3">
            <CustomText className="font-pBold text-xl">
              You've Selected:
            </CustomText>
            <ul className="list-disc px-6 pt-2">
              <li className="py-0">
                <Grid container justifyContent={"space-between"}>
                  <Grid item>
                    <CustomText className="font-pRegular text-sm">
                      {selBox?.boxCount} x Fresh Meals
                    </CustomText>
                  </Grid>
                  <Grid item>
                    <CustomText className="font-pRegular text-sm">
                      <s>${Number(selBox?.boxCount === 6 ? 79.98 : 99.98).toFixed(2)}</s>
                    </CustomText>
                  </Grid>
                </Grid>
              </li>
              <li className="py-0">
                <Grid container justifyContent={"space-between"}>
                  <Grid item>
                    <CustomText className="font-pRegular text-sm">
                      480g Servings
                    </CustomText>
                  </Grid>
                  <Grid item>
                    <CustomText className="font-pBold text-xl/6 text-[#fc0800]">
                      ${Number(selBox?.totalPrice).toFixed(2)}
                    </CustomText>
                  </Grid>
                </Grid>
              </li>
              <li className="py-0">
                <Grid container justifyContent={"space-between"}>
                  <Grid item>
                    <CustomText className="font-pRegular text-sm">
                      Free Delivery
                    </CustomText>
                  </Grid>
                  <Grid item>
                    <CustomText className="font-pRegular text-sm">
                      ${selBox?.mealPrice} Per Meal
                    </CustomText>
                  </Grid>
                </Grid>
              </li>
              <li className="pt-2 list-none">
                <Grid container justifyContent={"flex-end"}>
                  <Grid item>
                    <CustomText className="font-semibold text-base text-[#fc0800]">
                      BLACK FRIDAY DISCOUNT
                    </CustomText>
                  </Grid>
                </Grid>
              </li>
            </ul>
          </div>
        )}
        <Button
          variant="contained"
          disabled={!Boolean(selBox?.boxCount)}
          fullWidth
          size="large"
          onClick={handleNext}
          className="mt-5"
        >
          Select Your Meals
        </Button>
      </div>
    </div>
  );
};

export default MealCount;
