import { IOrder } from "redux/model";
import { apiPath } from "utility/apiPaths";
import axios from "utility/axios";
import {
  APIResponseCode,
  APIResponseType,
  IBlackoutDay,
  TDeliveryDate,
} from "utility/types";
import { APIService } from "./api.service";

class OrderService extends APIService<IOrder> {
  async gets({
    userId,
    delivered = false,
  }: {
    userId: string;
    delivered?: boolean;
  }): Promise<APIResponseType<IOrder[]>> {
    try {
      const { data } = await axios.post(apiPath.GET_USER_ORDER, {
        userId,
        delivered,
      });
      return {
        code: APIResponseCode.SUCCESS,
        data: data,
      } as APIResponseType<IOrder[]>;
    } catch (error) {
      return {
        code: APIResponseCode.FAILED,
        data: [] as IOrder[],
        message: "Network Connection Problem",
      };
    }
  }
  async save({
    data,
    userId = "",
    count = 6,
    customerId = "",
  }: {
    data?: IOrder;
    userId: string;
    count: number;
    customerId: string;
  }): Promise<APIResponseType<any>> {
    try {
      const { data: order } = await axios.post(apiPath.CREATE_ORDER, {
        data,
        userId,
        count,
        customerId,
      });
      return {
        code: APIResponseCode.SUCCESS,
        data: order,
      } as APIResponseType<{ order: IOrder; balance?: number }>;
    } catch (error: any) {
      return {
        code: APIResponseCode.FAILED,
        data: {} as { order: IOrder },
        message: error?.response?.data?.message ?? "Network Connection Problem",
      };
    }
  }
  async addWithPromocode({
    data,
    userId = "",
    count = 6,
  }: {
    data?: IOrder;
    userId: string;
    count: number;
  }): Promise<APIResponseType<IOrder>> {
    try {
      const { data: order } = await axios.post(apiPath.CREATE_ORDER_PROMO, {
        data,
        userId,
        count,
      });
      return {
        code: APIResponseCode.SUCCESS,
        data: order,
      } as APIResponseType<IOrder>;
    } catch (error) {
      return {
        code: APIResponseCode.FAILED,
        data: {} as IOrder,
        message: "Network Connection Problem",
      };
    }
  }
  async calcDeliveryDates(
    blackoutDays: IBlackoutDay[]
  ): Promise<APIResponseType<TDeliveryDate[]>> {
    try {
      const { data: deliveryDates } = await axios.post(
        apiPath.CALC_DELIVERY_DATES,
        {
          blackoutDays,
        }
      );

      return {
        code: APIResponseCode.SUCCESS,
        data: deliveryDates,
      } as APIResponseType<TDeliveryDate[]>;
    } catch (error) {
      return {
        code: APIResponseCode.FAILED,
        data: [] as TDeliveryDate[],
        message: "Network Connection Problem",
      };
    }
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new OrderService();
